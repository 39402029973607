import React from "react";
import { Modal } from "react-bootstrap";
import "./ModalAbout.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const ModalAbout = (props) => {
  return (
    <Modal
      fullscreen={true}
      {...props}
      size="lg"
      contentClassName="modal-about"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="modal-body-content">
          <div>
            <FontAwesomeIcon
              icon={faX}
              fontSize={28}
              color="#fff"
              onClick={() => props.onHide(false)}
            />
          </div>
          <h2
            className="text-center"
            style={{
              marginTop: "-30px",
            }}
          >
            Tentang Kami
          </h2>
          <ul className="tentang-kami-ul mx-md-5 p-0 fs-5">
            <li>
              Eksistensi Loginusa sebagai System Integrator yang berpengalaman
              menangani project yang syarat dengan integrase yang di harapkan
              dapat memenuhi kebutuhan perusahaan untuk mengintegrasikan Web &
              Mobile Work Force Application.
            </li>
            <li>
              Kemampuan dan pengalaman Loginusa Automation
              mendevelop/mengintegrasikan multi Protocol, di harapkan dapat
              sesuai dengan kebutuhan perusahaan yang ingin mengintegrasikan
              dengan eksisting System dengan new System.
            </li>
            <li>
              Kompetensi Loginusa yang mengembangkan API, di harapkan akan
              memberikan keuntungan bagi perusahaan karena menyediakan integrase
              ke ERP Existing system seperti SAP, Odoo, Oracle, Microsoft dsb,
              dari existing system Customer.
            </li>
            <li>
              Kemampuan Loginusa dalam bidang Big Data Analytics & Machine
              Learning memberikan keuntungan bagi perusahaan untuk dapat
              melakukan penglohaan data volume besar, kecepatan tinggi dan data
              yang bervariasi, yang selanjutnya dapat di visualisasikan juga
              membantu decision maker dalam pengambilan keputusan berdasarkan
              analytics reporting yang ada.
            </li>
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAbout;

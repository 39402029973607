const assets = "/assets/ourteam/";
const defaultImg = "assets/ourteam/default.jpg";

export const Teams = [
  {
    name: "Tias Ade Putra",
    jobdesk: "Chief Exceutive Officer",
    quotes: "Sebaik-baik aplikasi adalah yang bermanfaat bagi penggunanya.",
    img: assets + "mas tias.jpg",
  },
  {
    name: "M. Fachmi Rizal",
    jobdesk: "Chief Technology Officer",
    quotes: "Sederhana adalah tingkat kompleksitas yang sempurna.",
    img: assets + "mas fachmi.jpg",
  },
  {
    name: "Mirza Halim",
    jobdesk: "Chief Sales & Marketing Development Representative",
    quotes: "You are what yout think, so think big & always positive.",
    img: assets + "mirza-halim.jpg",
  },
  {
    name: "Fadholi Rahman Siregar",
    jobdesk: "Full Stack Developer",
    quotes: "Don’t stop when u are tired. Stop when u are done.",
    img: assets + "fadholi.jpg",
  },
  {
    name: "Ferdyfian Aziizul",
    jobdesk: "Developer Loginusa",
    quotes: "Only you can change your life. Nobody else can do it for you.",
    img: assets + "ferdy.jpg",
  },
  // {
  //   name: "Syifa Tazfa",
  //   jobdesk: "Developer Loginusa",
  //   quotes: "Stay positive. Attitude is everything.",
  //   img: assets + "syifa-tazfa.jpeg",
  // },
  {
    name: "Ahmad Khalish Shafi",
    jobdesk: "UI/UX Designer",
    quotes: "Stay positive. Attitude is everything.",
    img: assets + "khalis.jpg",
  },
  // {
  //   name: "Faiz Fadhlur",
  //   jobdesk: "UI/UX Designer",
  //   quotes: "Change your thoughts and your change your world.",
  //   img: assets + "faiz.jpg",
  // },
  {
    name: "Anugrah Tribima",
    jobdesk: "Full Stack Developer",
    quotes: "Lakukan Apa yang Membuatmu Bahagia.",
    img: assets + "anugrah-tribima.jpg",
  },
  {
    name: "Sandyco Torina",
    jobdesk: "IT Functional ERP",
    quotes: "Berhenti Menyalahkan Segalanya.",
    img: assets + "sandyco-torina.jpeg",
  },
  {
    name: "Nasywa Alif Annisa",
    jobdesk: "Project Management Officer & Technical Writer",
    quotes: "Jangan Pernah Menyerah Apapun yang Terjadi.",
    img: assets + "nasywa-alif.jpeg",
  },
  // {
  //   name: "Ilyas Nurrahman",
  //   jobdesk: "Developer Loginusa",
  //   quotes:
  //     "Tidak ada kata terlambat untuk mulai menciptakan kehidupan yang kamu inginkan.",
  //   img: assets + "Mas Ilyas.jpg",
  // },
  {
    name: "Husnul Hotimah",
    jobdesk: "Full Stack Developer",
    quotes: "Hidup adalah serangkaian langkah kecil.",
    img: assets + "Mba Husnul.jpg",
  },
  {
    name: "Brilian",
    jobdesk: "Full Stack Developer",
    quotes: "Kebahagiaan hidupmu bergantung pada kualitas pikiranmu.",
    img: assets + "Mas Brilian.jpg",
  },
  {
    name: "Mujahidin Fajar Abdullah",
    jobdesk: "Full Stack Developer",
    quotes: "Selama Anda hidup, teruslah belajar bagaimana hidup.",
    img: assets + "Mas Fajar.jpg",
  },
  {
    name: "Astri Puspaningrum",
    jobdesk: "Senior Openbravo ERP Technical Consultant",
    quotes: "Bukan Kita yang Hebat, Tapi Karena Allah Yang Mudahkan",
    img: assets + "astri-puspaningrum.png",
  },
  {
    name: "Amelia Hermawani",
    jobdesk: "Senior Openbravo ERP Functional Consultant",
    quotes:
      "Most people don’t know how to listen because the major part of their attention is taken up by thinking. -Eckhart Tolle",
    img: assets + "amelia-hermawani.jpeg",
  },
  {
    name: "Reza Adi P",
    jobdesk: "IT Functional ERP",
    quotes: "Selalu Lakukan Perubahan Kecil ke Arah yang Lebih Baik",
    img: assets + "reza adi p.jpg"
  },
  {
    name: "Raihan Marhandika",
    jobdesk: "Full Stack Developer",
    quotes: "Tetap haus dan lapar, jangan mudah kenyang",
    img: assets + "raihan marhandika.jpg"
  },
  {
    name: "Desti Puspitadewi",
    jobdesk: "Sekertaris",
    quotes: "Kehidupan adalah petualangan yang mengesankan, jangan lupa untuk menikmatinya",
    img: assets + "desti puspitadewi.jpg"
  },
  {
    name: "Hadi Ferdiansyah",
    jobdesk: "Desapedia Functional Support",
    quotes: "Berjalan lah sesuai rule yang kau yakini... saat keyakinan penuh pasti kau dapatkan apa yang kita inginkan SEMANGAT",
    img: assets + "hadi ferdiansyah.jpg"
  }
];

import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const Maps = () => {
  return (
    <MapContainer
      className="border shadow mt-5"
      center={[-6.321875465019802, 107.0000531300617]}
      zoom={15}
      maxZoom={20}
      maxNativeZoom={15}
      scrollWheelZoom={true}
      style={{
        width: "100%",
        height: "70vh ",
        borderRadius: "20px",
      }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors Mapbox'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxZoom={20}
        maxNativeZoom={15}
      />
      <Marker position={[-6.321875465019802, 107.0000531300617]}>
        <Popup>
          <a
            href="https://goo.gl/maps/FkA6AtF2tBkwZHa39"
            style={{
              textDecoration: "none",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            CV. Teknologi Nusantara
          </a>
          <br />
          IT Solution & Software House
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Maps;

import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";

import "./NavigationBar.css";

const NavigationBar = () => {
  const [navbar, setNavbar] = useState(false);

  const listenScrollEvent = (e) => {
    if (window.scrollY > 200) {
      setNavbar(true);
    } else if (window.scrollY < 200) {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.addEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <Navbar
      className={`navbar ${navbar && "nav-color shadow"}`}
      expand="lg"
      fixed="top"
    >
      <Navbar.Brand className="nav-brand" href="#home">
        <img src="assets/logo/logo.png" alt="loginusa logo" width={128}></img>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link className="nav-link" href="#about">
            <div>ABOUT</div>
          </Nav.Link>
          <Nav.Link className="nav-link" href="#ourteam">
            <div>OUR TEAM</div>
          </Nav.Link>
          <Nav.Link className="nav-link" href="#visi-misi-komitmen">
            <div>VISI MISI & KOMITMEN</div>
          </Nav.Link>
          <Nav.Link className="nav-link" href="#layanan">
            <div>LAYANAN</div>
          </Nav.Link>
          <Nav.Link className="nav-link" href="#teknologi">
            <div>TEKNOLOGI</div>
          </Nav.Link>
          <Nav.Link className="nav-link" href="#portfolio">
            <div>PORTFOLIO</div>
          </Nav.Link>
          <Nav.Link className="nav-link" href="#contact">
            <div>CONTACT</div>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;

import React from "react";
import UpButton from "./UpButton";

const Index = () => {
  return (
    <div>
      <UpButton />
    </div>
  );
};

export default Index;

import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import style from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const index = ({ show, handleClose, data }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      fullscreen
      contentClassName={style.parent}
    >
      <h2 className={style.title}>Our Team</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "30px 60px",
          paddingBottom: "0",
        }}
      >
        <FontAwesomeIcon
          icon={faX}
          color="#fff"
          fontSize={32}
          style={{
            cursor: "pointer",
          }}
          onClick={() => handleClose(false)}
        />
      </div>
      <Modal.Body>
        <div className={style.row}>
          {data.map((i) => {
            return (
              <span className={style.column}>
                <span>
                  <div>{i.name}</div>
                  <div>{i.jobdesk}</div>
                </span>
                <img src={i.img} alt={`loginusa team ${i.name}`}></img>
              </span>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default index;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./UpButton.css";

const UpButton = () => {
  const [upBtn, setUpBtn] = useState();

  const listenScrollEvent = (e) => {
    if (window.scrollY > 200) {
      setUpBtn(true);
    } else if (window.scrollY < 200) {
      setUpBtn(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.addEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <div className={`fixed-bottom ${upBtn ? "show" : ""}`}>
      <a href="#home" className="up-button rounded-circle p-1 bg-white mb-3">
        <FontAwesomeIcon
          icon={faAngleUp}
          fontSize="60px"
          width={60}
          height={60}
          color="#4B97D5"
        />
      </a>
      <a href="https://wa.me/6281381432012" target="_blank" rel="noreferrer">
        <img
          src="assets/icons/floating-button/whatsapp.png"
          alt="loginusa contact"
          width={75}
        ></img>
      </a>
    </div>
  );
};

export default UpButton;

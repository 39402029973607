import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <div className="footer border-top shadow">
      <Container>
        <Row className="footer-row mb-5">
          <Col md="3" className="me-5">
            <a href="#home">
              <img
                src={"assets/logo/logo.png"}
                alt=""
                className="footer-img img-fluid"
              ></img>
            </a>
          </Col>
          <Col md="3" className="footer-col">
            <div className="footer-h">Contact</div>
            <p className="footer-p">
              Mirza Halim
              <br />
              <a href="https://wa.me/6281381432012">0813-8143-2012</a>
              <br />
              <a href="mailto:mirza.halim@loginusa.id">
                mirza.halim@loginusa.id
              </a>
            </p>
            <div className="footer-h">Office Hour</div>
            <p>Monday to Friday / 08:00 - 17:00</p>
          </Col>

          <Col className="footer-col">
            <div className="footer-h">Address</div>
            <a href="https://g.co/kgs/rPfRHz" className="footer-p">
              Ruko Pasar Alam, Jalan Alun-Alun Selatan No. 46, RT.004/RW.016.
              Kel. Padurenan Kec. Mustika Jaya, Bumi Pala, Vida, Bekasi City,
              West Java 16340
            </a>
          </Col>

          {/* <Col md="2" className="footer-col">
            <div className="footer-h">Explore</div>
            <a href="#about">About</a>
            <br />
            <a href="#visi-misi-komitmen">Visi Misi & Komitmen</a>
            <br />
            <a href="#ourteam">Our Team</a>
            <br />
            <a href="#layanan">Layanan</a>
            <br />
            <a href="#teknologi">Teknologi</a>
            <br />
            <a href="#layanan">Portfolio</a>
            <br />
          </Col>
          <Col md="2" className="footer-col">
            <div className="footer-h">Services</div>
            <a href="#layanan">Mobile Application</a>
            <br />
            <a href="#layanan">Website Application</a>
            <br />
            <a href="#layanan">Database Optimization</a>
            <br />
            <a href="#layanan">UI / UX Design</a>
            <br />
            <a href="#layanan">Integration System</a>
            <br />
          </Col> */}
        </Row>
        <hr />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <FontAwesomeIcon
            onClick={() =>
              (window.location.href =
                "https://www.facebook.com/Loginusa-Teknologi-Nusantara-581946988640501/")
            }
            icon={brands("facebook")}
            fontSize="18px"
            width="100%"
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              margin: "8px",
              padding: "5px",
              border: "solid 3px #4B97D4",
              cursor: "pointer",
            }}
          />
          <FontAwesomeIcon
            onClick={() =>
              (window.location.href =
                "https://www.instagram.com/teknologi_nusantara/")
            }
            icon={brands("instagram")}
            fontSize="18px"
            width="100%"
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              margin: "8px",
              padding: "5px",
              border: "solid 3px #4B97D4",
              cursor: "pointer",
            }}
          />
          <FontAwesomeIcon
            onClick={() => (window.location.href = "#")}
            icon={brands("twitter")}
            fontSize="18px"
            width="100%"
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              margin: "8px",
              padding: "5px",
              border: "solid 3px #4B97D4",
              cursor: "pointer",
            }}
          />
        </div>
      </Container>
    </div>
  );
};

export default Footer;

import React from "react";
import "./Projects.css";
import { Carousel } from "react-bootstrap";
import ViewPortChecker from "../../../lib/ViewPortChecker";
import { ListPortfolio } from "./ListPortfolio";

const Projects = () => {
  const projectSrc = ListPortfolio;

  return (
    <div className="projects-container">
      {projectSrc.reverse().map((data) => {
        return (
          <div className="projects-list">
            <h2>{data.perusahaan.toLocaleUpperCase()}</h2>
            <div>{data?.title}</div>
            <Carousel>
              {data.src.map((datas) => {
                return ViewPortChecker().isTabletOrMobile ? (
                  datas.map((src) => {
                    return (
                      <Carousel.Item interval={10000000}>
                        <div className="carousel-projects mobile rounded">
                          <img
                            key={src}
                            className={"projects-image mobile"}
                            src={src}
                            alt={`${data.title}`}
                            width="100%"
                            height="auto"
                          ></img>
                        </div>
                      </Carousel.Item>
                    );
                  })
                ) : (
                  <Carousel.Item interval={10000000}>
                    <div className="carousel-projects">
                      {datas.map((src) => {
                        return (
                          <img
                            key={src}
                            className={"projects-image rounded"}
                            src={src}
                            alt={`${data.title}`}
                            height="500px"
                          ></img>
                        );
                      })}
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        );
      })}
    </div>
  );
};

export default Projects;

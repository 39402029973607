import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import style from "./OurTeam.module.css";
import { Carousel } from "react-bootstrap";
import "./carousel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";

import { Teams } from "./Teams";

import Expand from "./Expand";
import ViewPortChecker from "../../../lib/ViewPortChecker";

const OurTeam = () => {
  const [index, setIndex] = useState(0);

  const teams = Teams;

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const arrayChunk = (arr, n) => {
    const array = arr.slice();
    const chunks = [];
    while (array.length) chunks.push(array.splice(0, n));
    return chunks;
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className={style.team} id="ourteam">
      <Fade direction="up" triggerOnce>
        <h2 className={style.title}>Our Team</h2>
        <div
          style={{
            width: "100%",
            padding: "0 60px",
            display: "flex",
            justifyContent: "flex-end",
            zIndex: "-1",
          }}
        >
          <FontAwesomeIcon
            className={`${style.expand} rounded-circle`}
            icon={faExpand}
            color="#3F9CD5"
            onClick={handleShow}
            title="Expand our team"
          />
          <Expand show={show} handleClose={handleClose} data={teams} />
        </div>

        {/* responsive */}
        <Carousel activeIndex={index} onSelect={handleSelect}>
          {arrayChunk(teams, ViewPortChecker().isTabletOrMobile ? 1 : 3).map(
            (row, i) => {
              return (
                <Carousel.Item
                  key={Math.random()}
                  interval={100000000}
                  style={{ width: "100%" }}
                >
                  <div className={style.container}>
                    {row.map((data) => {
                      return (
                        <span key={Math.random()} className={style.images}>
                          <div className={style["hover-container"]}>
                            <div>
                              <div>{data.name}</div>
                              <div>{data.jobdesk}</div>
                              <div>"{data.quotes}"</div>
                            </div>
                          </div>
                          <img
                            src={data.img}
                            alt={`${data.name}, ${data.jobdesk} di CV. Teknologi Nusantara`}
                            width="310px"
                            height={"348px"}
                            style={{
                              objectFit: "cover",
                            }}
                            className="shadow"
                          />
                        </span>
                      );
                    })}
                  </div>
                </Carousel.Item>
              );
            }
          )}
        </Carousel>
      </Fade>
    </div>
  );
};

export default OurTeam;

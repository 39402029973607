import React from "react";
import { Fade } from "react-awesome-reveal";
import Title from "../reused-components/Title/Title";
import Projects from "./Projects";
import style from "./Portfolio.module.css";

import ViewPortChecker from "../../../lib/ViewPortChecker";
import { Carousel } from "react-bootstrap";

const Portfolio = () => {
  const client = [
    "assets/client/1.png",
    "assets/client/2.png",
    "assets/client/3.png",
    "assets/client/4.png",
    "assets/client/5.png",
    "assets/client/6.png",
    "assets/client/7.png",
    "assets/client/8.png",
    "assets/client/9.png",
    "assets/client/10.png",
  ];

  // mecah array jadi 4
  const arrayChunk = (arr, n) => {
    const array = arr.slice();
    const chunks = [];
    while (array.length) chunks.push(array.splice(0, n));
    return chunks;
  };

  return (
    <div className={style.portfolio} id="portfolio">
      <Fade triggerOnce>
        <Title>Portfolio</Title>

        {/* client img */}
        {ViewPortChecker().isTabletOrMobile ? (
          <Carousel>
            {arrayChunk(client, 1).map((row, i) => {
              console.log(row);
              return (
                <Carousel.Item interval={100000}>
                  <div className={style.client}>
                    {row.map((data) => {
                      return (
                        <div className={style["client-img"]}>
                          <img src={data} alt="client img"></img>
                        </div>
                      );
                    })}
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        ) : (
          <div
            style={{
              textAlign: "center",
              padding: "0 60px",
              paddingBottom: "60px",
            }}
          >
            {client.map((c) => {
              return (
                <span
                  style={{
                    margin: "0 20px",
                  }}
                >
                  <img src={c} alt="client img"></img>
                </span>
              );
            })}
          </div>
        )}
      </Fade>
      <Fade triggerOnce>
        <Projects />
      </Fade>
    </div>
  );
};

export default Portfolio;

import React from "react";
import { Fade } from "react-awesome-reveal";
import { Col, Row } from "react-bootstrap";
import visimisikomitmen from "./VisiMisiKomitmenText";
import "./VisiMisiKomitmen.css";

const VisiMisiKomitmen = () => {
  const visi = visimisikomitmen.visi;
  const misi = visimisikomitmen.misi;
  const komitmen = visimisikomitmen.komitmen;

  return (
    <div className="visi-misi-komitmen " id={"visi-misi-komitmen"}>
      <div className="circle"></div>
      <div className="circle-2"></div>

      <Fade triggerOnce>
        <Row className="visimisi-wrapper mx-md-5">
          <Col md="6">
            <div className="first-row left">
              <h2>{visi.title}</h2>
              <hr />
              {visi.desc.map((desc) => (
                <p key={Math.random()}>{desc}</p>
              ))}
            </div>
          </Col>
          <Col md="6">
            <div className="first-row right">
              <h2>{misi.title}</h2>
              <hr />
              <ul>
                {misi.desc.map((desc) => (
                  <li key={Math.random()}>{desc}</li>
                ))}
              </ul>
            </div>
          </Col>
          <Col md="10">
            <div className="second-row">
              <h2>{komitmen.title}</h2>
              <hr />
              <ul>
                {komitmen.desc.map((desc) => (
                  <li key={Math.random()}>{desc}</li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Fade>
    </div>
  );
};

export default VisiMisiKomitmen;

const visimisikomitmen = {
  visi: {
    title: "VISI",
    desc: [
      "Menjadi perusahaan yang unggul di bidang pengembang solusi teknologi informasi pada tahun 2018",
    ],
  },

  misi: {
    title: "MISI",
    desc: [
      "Mewujudkan impian Customer dalam membangun system yang dapat menunjang kebutuhan dalam mempercepat proses bisnisnya",
      "Membangun dan menjaga kepercayaan Customer akan kinerja team yang kami miliki",
      "Menjaga dan Meningkatkan kekompakan antar karyawan",
    ],
  },

  komitmen: {
    title: "KOMITMEN",
    desc: [
      "DIPERCAYA oleh Customer dan mitra strategis dengan menjunjung tinggi kejujuran, tanggung jawab, disiplin dan keterbukaan dalam upaya memenuhi komitmen.",
      "KEBERSAMAAN antara pengurus perusahaan, karyawan dan mitra strategis berdasarkan kepada sinergi, persatuan saling asah, asih dan asuh untuk mencapai target dan tujuan perusahaan.",
      "UNGGUL dengan berpegang pada profesionalisme, tahan uji dan kreativitas dalam upaya meningkatkan kualitas produk dan layanan melalui pengembangan design, teknologi dan metode perancangan yang efisien dan efektif.",
      "KEPUASAN CUSTOMER menjadi tujuan utama dengan tetap memperhatikan etika bisnis dan mengacu kepada ketepatan waktu, mutu produk dan biaya.",
    ],
  },
};

export default visimisikomitmen;

import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import ModalAbout from "./ModalAbout";
import style from "./AboutUs.module.css";
import { Col, Row } from "react-bootstrap";

const AboutUs = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className={style.about} id="about">
      <Fade direction="up" triggerOnce>
        <Row className={style.container}>
          <Col lg={6}>
            <img
              src="/assets/anggota.jpeg"
              alt="anggota loginusa"
              className={style.left}
            ></img>
          </Col>
          <Col lg={6}>
            <div className={style.right}>
              <h2>Tentang Kami</h2>
              <p>
                Kami lebih utama bergerak di bidang Software Loginusa berdiri
                pada tahun 2014 dan berpengalaman selama 7 tahun, kami dapat
                bekerja sama dengan client kami dengan baik, Tim kami memiliki
                hubungan yang solid, berpengalaman dan memiliki budaya kerja
                yang baik.{" "}
                <span onClick={() => setModalShow(true)}>selengkapnya...</span>
                <ModalAbout
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </p>
            </div>
          </Col>
        </Row>
      </Fade>
    </div>
  );
};

export default AboutUs;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Maps from "../Maps";
import styles from "./index.module.css";

const index = () => {
  return (
    <div id="contact" className={styles.container}>
      <div className={styles.title}>Contact Us!</div>
      <Row className={styles.row}>
        <Col className={`${styles.col} border shadow`}>
          <a
            href="https://wa.me/6281381432012"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="assets/icons/contact/whatsapp.png"
              alt="loginusa whatsapp"
              width={80}
            ></img>
            <div>Whatsapp</div>
            <span>Mirza Halim</span>
            <p>081381432012</p>
          </a>
        </Col>
        <Col className={`${styles.col} border shadow`}>
          <a href="mailto:mirza.halim@loginusa.id">
            <img
              src="assets/icons/contact/email.png"
              alt="loginusa mail"
              width={80}
            ></img>
            <div>Email</div>
            <span>Mirza Halim</span>
            <p>mirza.halim@loginusa.id</p>
          </a>
        </Col>
        <Col className={`${styles.col} border shadow`}>
          <a
            href="https://goo.gl/maps/FkA6AtF2tBkwZHa39"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="assets/icons/contact/location.png"
              alt="loginusa location"
              width={80}
            ></img>
            <div>Visit Us</div>
            <p>
              Ruko Pasar Alam No. 46, Jalan Alun-Alun Selatan, RT.004/RW.016,
              Padurenan, Kec. Mustika Jaya, Vida Bekasi, Jawa Barat 16340
            </p>
          </a>
        </Col>
      </Row>
      <Maps />
    </div>
  );
};

export default index;

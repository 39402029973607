import React from "react";
import NavigationBar from "./NavigationBar/NavigationBar";
import Home from "./body/Home/Home";
import AboutUs from "./body/AboutUs/AboutUs";
import VisiMisiKomitmen from "./body/VisiMisiKomitmen/VisiMisiKomitmen";
import Layanan from "./body/Layanan/Layanan";
import Teknologi from "./body/Teknologi/Teknologi";
import Portfolio from "./body/Portfolio/Portfolio";
import Footer from "./Footer/Footer";
import Contact from "./body/Contact/index";

// import StickyFooter from "react-sticky-footer";
import "./index.css";
import OurTeam from "./body/OurTeam/OurTeam";
// import ViewPortChecker from "../lib/ViewPortChecker";

const Components = () => {
  return (
    <>
      <NavigationBar />

      <div className="home-about">
        <Home />
        <AboutUs />
      </div>
      <OurTeam />
      <VisiMisiKomitmen />
      <Layanan />
      <Teknologi />
      <Portfolio />
      <Contact />

      <Footer />
      
    </>
  );
};

export default Components;

import React from "react";
import { Fade } from "react-awesome-reveal";
import { Row, Col } from "react-bootstrap";

import "./Layanan.css";

const Layanan = () => {
  const services = [
    {
      title: "Mobile App",
      desc: "Program Aplikasi yang di rancang untuk berjalan pada peranti bergerak seperti ponsel atau tablet.",
      logo: "assets/services/mobile-app.png",
    },
    {
      title: "Web App",
      desc: "Suatu aplikasi yang dapat diakses menggunakan penjelajah web melalui suatu jaringan seperti Internet",
      logo: "assets/services/web-app.png",
    },
    {
      title: "Database Optimization",
      desc: "Optimasi database adalah strategi untuk mengurangi waktu respon sistem database.",
      logo: "assets/services/database.png",
    },
    {
      title: "Integration System",
      desc: "Didefinisikan dalam rekayasa sebagai proses menyatukan sub-sistem komponen kedalam satu sistem dan memastikan bahwa subsistem berfungsi bersama sebagai suatu sistem, dan dalam teknologi",
      logo: "assets/services/integration.png",
    },
    {
      title: "UI / UX Design",
      desc: "UI Design adalah tampilan produk yang ingin kita perlihatkan. UX berfokus pada proses pembuatan produk hingga mampu mendapatkan pengalaman kemudahan dari user",
      logo: "assets/services/ux-interface.png",
    },
    {
      title: "Openbravo ERP Implementation",
      desc: "Openbravo ERP adalah sebuah aplikasi yang terintegrasi dari beberapa sistem dengan tujuan untuk membantu, mengelola dan meningkatkan kinerja perusahaan",
      logo: "assets/services/openbravo.png",
    },
  ];

  return (
    <div className="layanan" id="layanan">
      <Fade triggerOnce>
        <div className="layanan-header">Layanan Kami</div>
        <div className="layanan-desc">
          Berikut adalah layanan yang kami layani
        </div>
        <Row className="d-flex justify-content-center m-0 p-0">
          {services.map((service) => {
            return (
              <Col md="3" className="layanan-col">
                <img
                  src={service.logo}
                  alt="loginusa service"
                  height="74.05px"
                ></img>
                <div className="layanan-title">{service.title}</div>
                <p className="layanan-p">{service.desc}</p>
              </Col>
            );
          })}
        </Row>
      </Fade>
    </div>
  );
};

export default Layanan;

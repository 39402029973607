import React from "react";
import { Fade } from "react-awesome-reveal";
import { Col, Row } from "react-bootstrap";
import ViewPortChecker from "../../../lib/ViewPortChecker";

import style from "./Home.module.css";

const Home = () => {
  const about = () => (window.location.href = "#about");
  const portfolio = () => (window.location.href = "#portfolio");

  return (
    <div className={style.home} id="home">
      <Fade triggerOnce>
        <div className={style.container}>
          <div className={style.left}>
            <img
              src="/assets/images/home/title.png"
              alt="Teknologi Nusantara - IT Solution & Software House"
              width={"100%"}
            ></img>
            <p style={{}}>IT Solution & Software House</p>
            <Row className="mt-5 pt-2">
              <Col
                md={6}
                style={{
                  display: "flex",
                  alignItems: "start",
                  marginBottom: "5px",
                }}
              >
                <button onClick={about}>ABOUT</button>
              </Col>
              <Col
                md={6}
                style={{
                  display: "flex",
                  alignItems: "start",
                }}
              >
                <button onClick={portfolio}>PORTFOLIO</button>
              </Col>
            </Row>
          </div>
          {ViewPortChecker().isTabletOrMobile ? (
            <></>
          ) : (
            <div className={style.right}>
              <img
                src="assets/images/home-vector.png"
                alt={"loginusa vector"}
              ></img>
            </div>
          )}
        </div>
      </Fade>
    </div>
  );
};

export default Home;
